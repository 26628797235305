.nav-link.active.custom-navbar-btn-clicked {
  background-color: #5a5a5b;
  color: #fff;
  border-radius: 3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    border-color 0.03s ease-in-out, box-shadow 0.3s ease-in-out;
}

.nav-link.active.custom-navbar-btn-clicked:hover {
  background-color: #9c9c9c;
}

.nav-link.active:hover {
  background-color: #9c9c9c;
  color: #fff;
  border-radius: 3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    border-color 0.03s ease-in-out, box-shadow 0.3s ease-in-out;
}
