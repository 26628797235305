.background-custom-inner {
  background: linear-gradient(-45deg, #0000 1.3%, #e2e3e5 0 32%, #0000 0),
    linear-gradient(45deg, #0000 48%, #000000 0 52%, #0000 0),
    linear-gradient(45deg, #0000 1.3%, #e2e3e5 0 32%, #0000 0) calc(24px / 2)
      calc(24px / 2),
    linear-gradient(-45deg, #0000 48%, #000000 0 52%, #0000 0) #e2e3e5;
  background-size: calc(2 * 24px) 24px, 24px 24px;
}

.background-custom {
  background-image: linear-gradient(
    to left,
    #000000,
    #3b3b3b,
    #777777,
    #b9b9b9,
    #ffffff
  );
  /* background-image: linear-gradient(
    to left,
    #363636,
    #636363,
    #949494,
    #c8c8c8,
    #ffffff
  ); */
}

.fs-1.text-black:hover {
  color: gray !important;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 0px; */
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.myWork-background {
  /* background-image: radial-gradient(circle, #ffffff, #e5e5e5, #cbcbcb, #b2b2b2, #9a9a9a); */
  background-image: radial-gradient(circle, #ffffff, #ededed, #dadada, #c9c9c9, #b7b7b7);
}

.custom-link {
  color: blue;
	background-image: linear-gradient(to bottom, transparent 65%, #D7D7D7 0);
	background-size: 0% 100%;
	background-repeat: no-repeat;
	text-decoration: none;
	transition: background-size .4s ease;
}

.custom-link:hover {
  background-size: 100% 100%;
	cursor: pointer;
}

.fs-4.text-black:hover {
  color: rgb(187, 187, 187) !important;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}